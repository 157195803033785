import styled from "styled-components";

const getGridItemColumn = (item) => {
  switch (item) {
    case 1:
      return "1 / 11";
    case 2:
      return "1 / 6";
    case 3:
      return "6 / 11";
    case 4:
      return "6 / 11";
    default:
      return "none";
  }
};

const getGridItemRow = (item) => {
  switch (item) {
    case 1:
      return "1 / 6";
    case 2:
      return "6 / 13";
    case 3:
      return "6 / 10";
    case 4:
      return "10 / 14";
    default:
      return "none";
  }
};

export const Container = styled.div`
  display: flex;

  @media screen and (max-width: 800px) {
    margin: auto;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(10, 3fr);
  grid-template-rows: repeat(13, 5fr);
  width: 700px;
  height: 900px;
  padding: 56px 0;
  position: absolute;
  right: 0;

  @media screen and (max-width: 1450px) {
    width: 500px;
  }

  @media screen and (max-width: 1048px) {
    width: 450px;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const ImageGridItem = styled.div`
  grid-column: ${({ item }) => getGridItemColumn(item)};
  grid-row: ${({ item }) => getGridItemRow(item)};
`;

export const HeaderContainer = styled.div`
  text-align: left;
  padding: 56px 0;
`;

export const Information = styled.div`
  margin: 32px 0;
`;

export const ServiceInformationContainer = styled.div`
  width: 600px;
  max-width: 50%;

  @media screen and (min-width: 1800px) {
    padding-left: 128px;
  }

  @media screen and (max-width: 1048px) {
    margin-left: -64px;
  }

  @media screen and (max-width: 800px) {
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }
`;
