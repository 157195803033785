import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {
  Container,
  Grid,
  HeaderContainer,
  Information,
  Image,
  ImageGridItem,
  ServiceInformationContainer,
} from "./service-information-elements";
import Border from "../border/border";
import image1 from "../../images/background.jpg";
import image2 from "../../images/inTruck2.png";
import image3 from "../../images/dollyWithBoxes.png";
import image4 from "../../images/stackedBoxes.png";

const useStyles = makeStyles({
  header1: {
    marginBottom: 48,
  },
  header2: {
    marginBottom: 32,
    color: "#51b53f",
  },
});

const ServiceInformation = () => {
  const classes = useStyles();
  return (
    <>
      <Container>
        <ServiceInformationContainer>
          <HeaderContainer>
            <Border />
            <Typography variant="h1" component="h1" className={classes.header1}>
              We craft our service to fit your needs.
            </Typography>
            <Typography variant="subtitle1" component="p">
              Our goal is to make your life easier. We offer a variety of services to help you pack,
              organize and move. See below for some of most common services. Don't see a service
              that fits you? Contact us so we can personalize a move that best suits you.
            </Typography>
          </HeaderContainer>

          <div>
            <Information>
              <Typography variant="h6" component="p" className={classes.header2}>
                Labor Only
              </Typography>
              <Typography variant="body1" component="p">
                Do you need an extra hand? This is the service for you. We can provide you with a
                team of skilled, friendly workers to help you with whatever labor is needed. This
                service is popular for home staging, furniture moving or loading a truck if you
                decided to host your own move. If you have task to complete but need a little help,
                give us a call to plan your service!
              </Typography>
            </Information>

            <Information>
              <Typography variant="h6" className={classes.header2}>
                Packing Services
              </Typography>
              <Typography variant="body1">
                Packing can be the difference between a good or bad move. We offer a Packing Only
                service because we believe that it is one of the most important aspects of moving -
                packing done right will save you time and money.
                <br />
                <br />
                Our team is trained in organizing and securing your personal belongings. Whether you
                need just a few items or a multi-story house packed, we can get it done with ease.
              </Typography>
            </Information>

            <Information>
              <Typography variant="h6" className={classes.header2}>
                Long Distance
              </Typography>
              <Typography variant="body1">
                Moving to a different city or state is a big moment, and Brother Bear Moving Company
                would like to help welcome you to your new home. This type of move generally
                involves heavy appliances, furniture, personal belongings and more. We have a
                professional team that will tackle this move with a swift efficiency, so that you
                can relax and enjoy your new opportunity.
                <br />
                <br />
                Customize this move to best fit your own needs! This service contains the full
                package: pack, move and unpack. But if you would like to handpick your services, we
                will provide just what is necessary. All of this will be discussed on a phone call
                with your representative so that we come to the move prepared.
              </Typography>
            </Information>

            <Information>
              <Typography variant="h6" className={classes.header2}>
                Commercial Moving
              </Typography>
              <Typography variant="body1">
                There are many details to consider when choosing a moving company for your business,
                so it is important to select a well-trusted moving partner. At Brother Bear Moving
                Company we care for our fellow local businesses and treat your items with the upmost
                caution. We have experience in moving electronics, office furniture and much more.
                Have items that need to be disassembled? No problem! Our team can move a small
                office or an entire building.
                <br />
                <br />
                Brother Bear Moving Company understands how much your business means to you, your
                family, staff and friends. We are about community first, which is why we want to
                help you make your move. Putting you back in business quickly is our number one
                goal.
              </Typography>
            </Information>
          </div>
        </ServiceInformationContainer>

        <Grid>
          <ImageGridItem item={1}>
            <Image src={image1} />
          </ImageGridItem>
          <ImageGridItem item={2}>
            <Image src={image2} />
          </ImageGridItem>
          <ImageGridItem item={3}>
            <Image src={image3} />
          </ImageGridItem>
          <ImageGridItem item={4}>
            <Image src={image4} />
          </ImageGridItem>
        </Grid>
      </Container>
    </>
  );
};

export default ServiceInformation;
