import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentContainer from "../components/content-container/content-container";
import ServiceInformation from "../components/service-information/service-information";

const MovingPage = () => (
  <Layout>
    <SEO title="Moving" />
    <ContentContainer>
      <ServiceInformation />
    </ContentContainer>
  </Layout>
);

export default MovingPage;
